var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{attrs:{"id":"wrapper-header"}},[_c('canvas',{attrs:{"id":"rect"}}),_c('div',{staticClass:"content-header"},[_c('h1',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal",value:({
        delay: 700,
        distance: '0px',
        opacity: 0,
        duration: 0}),expression:"{\n        delay: 700,\n        distance: '0px',\n        opacity: 0,\n        duration: 0}"}],staticClass:"btn"},[_c('span',{staticClass:"text and"},[_vm._v("Webdesign ")]),_c('span',{staticClass:"text"},[_vm._v("Development")])]),_c('p',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal",value:({
        origin: 'left',
        delay: 100,
        distance: '900px',
        opacity: null,
        duration: 2000}),expression:"{\n        origin: 'left',\n        delay: 100,\n        distance: '900px',\n        opacity: null,\n        duration: 2000}"}],staticClass:"header-me"},[_c('strong',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal",value:({
          origin: 'right',
          delay: 100,
          distance: '900px',
          opacity: null,
          duration: 2000}),expression:"{\n          origin: 'right',\n          delay: 100,\n          distance: '900px',\n          opacity: null,\n          duration: 2000}"}],staticClass:"text"},[_vm._v("Florian Gläser")])])]),_c('TheScrollBottom',{staticClass:"icon-position"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }